import React, { useContext, useEffect, useState } from 'react'
import NoImage from "../../assets/images/NoImage.png";
import Customize from './Customize';
import { Modal } from 'antd';
import axios from 'axios';
import { userContext } from '../../context/Usercontext';
import ItemData from './Item/ItemData';
import Pizza from './Item/Pizza';
import Loading from '../loading/Loading';

function ProductDetails({ pizzacalzone, pdetail, setProductDetails, current, setCurrent, checkboxItem1, setCheckboxItem1, maxLevel, setmaxLevel }) {


    const { setSelectedItems, cartData, setCartData, sizedata, setIsLoading, selectVariation, setSelectVariation, size, setSize, crust, setCrust, pizzaName, setPizzaName, spice, setSpice, selectedModifiers, setSelectedModifiers, note, setNote, qty, setQty } = useContext(userContext)
    const [customize, setCustomize] = useState(false)
    const [databyID, setDatabyID] = useState([]);


    const getTopping = async () => {
        if (pdetail.isCreateYourOwn === 1) {
            try {
                setIsLoading(true);
                const responce = await axios.get(`${process.env.REACT_APP_URL}/api/PizzaToppingPrice?productId=${pdetail.productId}`);
                console.log("create own", responce);
            }
            catch (err) {
                console.log(err);
            }
            finally {
                setIsLoading(false)
            }
        }
    }

    // ADD TO CART 
    const handeAddtoCart = () => {
        setmaxLevel(0)
        const params = {
            produtDetail: pdetail,
            qty: qty,
            note: note,
            selectVariation: checkboxItem1,
            pSize: size,
            spice: spice,
            pCrust: crust,
            pizzaName: pizzaName,
            price: pdetail.isPizza === 1 ?
                Number(((size.sizePriceX1 + crust.modifierCost + checkboxItem1.reduce((total, item) => total + item.pizzaModifierPrice, 0)) * qty).toFixed(2))
                :
                Number(((pdetail.price + checkboxItem1.reduce((total, item) => total + item.price, 0)) * qty).toFixed(2))
        }
        const newArray = [...cartData, params]
        localStorage.setItem('cartData', JSON.stringify(newArray))
        setCartData(newArray)

        // Click cart and reset all variables
        setDatabyID([])
        setSelectedModifiers([])
        setCurrent(0)
        setCheckboxItem1([])
        setSelectVariation(null)
        setProductDetails(false)
        setNote("")
        setQty(1)
        setSize(sizedata[0])
        setSelectedItems([])
    }

    console.log("qty",qty);

    const closeModel = () => {
        setCustomize(false)
        setSelectedModifiers([])
    }

    const getProductItem = async () => {
        try {
            setIsLoading(true)
            const responce = await axios.get(`${process.env.REACT_APP_URL}/api/Variation/ByProductId?productId=${pdetail.productId}`);
            console.log("get product data ", responce);
            if (responce.status === 200) {
                setDatabyID(responce.data)
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getProductItem();
        getTopping();
    }, [pdetail.productId, current])



    return (
        <>
            <Loading />
            <div className='product-details-outer'>
                <div className='product-detail-inner'>
                    <div className='about-product'>
                        <h3 className='pname'>{pdetail.productName}</h3>
                        {pdetail.pricedesc === "" || pdetail.pricedesc === "0" ?
                            <h6 className='price'>${pdetail.price}</h6>
                            : ""}
                    </div>
                    <div className='product-img'>
                        <img src={NoImage} alt="" />
                    </div>
                </div>
                {pdetail.isPizza === 1 || pdetail.isCreateYourOwn === 1 || pdetail.isDeal === 1 ?
                    <Pizza {...{ spice, setSpice, pizzaName, setPizzaName, pizzacalzone, pdetail, note, setNote, qty, setQty, handeAddtoCart, setProductDetails, checkboxItem1, setCheckboxItem1, setCustomize, size, setSize, crust, setCrust, pizzaName }} />
                    :
                    <ItemData {...{ databyID, pdetail, qty, note, setQty, setNote, handeAddtoCart, setProductDetails, current, setCurrent, checkboxItem1, setCheckboxItem1, maxLevel, setmaxLevel, selectVariation, setSelectVariation }} />
                }
            </div>



            {/* PIZZA */}
            {pdetail.isPizza === 1 ?
                <Modal open={customize} onCancel={() => closeModel()} width={500} height={600}>
                    <Customize {...{ pdetail, checkboxItem1, setCheckboxItem1, setCustomize, selectedModifiers, setSelectedModifiers }} />
                </Modal>
                : ""}

        </>
    )
}

export default ProductDetails;
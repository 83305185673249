import React, { useContext, useEffect, useState } from "react";
import "../css/navbar.css";
import { userContext } from "../context/Usercontext";
import { Link, useNavigate } from "react-router-dom";

function Navbar() {
  const { handleClick, data, fatchData } = useContext(userContext);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigation = useNavigate();
  const handleCatagory = (index) => {
    setSelectedItem(index);
  };

  const handleAdminLogin = (loginType) => {
    localStorage.setItem("login_type", loginType);
    navigation("/login");
  };

  useEffect(() => {
    fatchData();
  }, [fatchData]);

  const myRealdata = JSON.parse(localStorage.getItem("loginUser"));
  const mylogindata = localStorage.getItem("login_type");

  const userLogout = () => {
    localStorage.removeItem("loginUser");
    navigation("/");
  };

  return (
    <>
      <div className="navbar-outer">
        <div className="nav-inner">
          <h2 className="title">RANGER POS</h2>
          <div>
            <div className="cart-btn">
              <button onClick={handleClick}>
                <i className="fa-brands fa-opencart"></i>
              </button>
            </div>

            {myRealdata ? (
              <>
                <div className="d-flex" style={{ width: "250px" }}>
                  <div className="row">
                    <div className="col d-flex">
                      <div className="row rounded-pill " style={{border:"2px solid #5e35b1"}}>
                        <div className="col d-flex align-items-center">
                          <i className="far fa-user me-3"></i> {mylogindata === "customer"
                            ? myRealdata.firstName
                            : myRealdata[0] && myRealdata[0].userName}
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="login_btn">
                        <button onClick={userLogout}>Logout</button>
                        <div className="login_box">
                          <Link to="/myorders">My Orders</Link>
                          <Link to="/customerdetail">Customer Details</Link>
                          <Link to="/changepassword">Change Password</Link>
                          <Link to="/" onClick={userLogout}>
                            Logout
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="login_btn">
                  <button>Login</button>
                  <div className="login_box">
                    <div onClick={() => handleAdminLogin("customer")}>
                      Customer Login
                    </div>
                    <div onClick={() => handleAdminLogin("admin")}>
                      Admin Login
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* <div class="btn-group">
              <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Login
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button onClick={() => handleAdminLogin('customer')}>Customer Login</button>
                <button onClick={() => handleAdminLogin('admin')}>Admin Login</button>
              </div>
            </div> */}
          </div>
        </div>

        <div className="mobile-outer">
          <ul className="mobile-nav-inner">
            {data.map(({ categoryDescription, id }, index) => (
              <li className="link">
                <Link
                  to={"/" + categoryDescription.replace(/\//, " ") + "/" + id}
                  style={
                    selectedItem === index
                      ? {
                          background: "#ede7f6",
                          color: "#5e35b1",
                          boxShadow:
                            "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                        }
                      : {}
                  }
                  className="catagory-name"
                  onClick={() => handleCatagory(categoryDescription, index)}
                >
                  {categoryDescription}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Navbar;

import React, { useContext, useEffect, useState } from 'react'
import { userContext } from '../../context/Usercontext';
import Loading from '../loading/Loading';
import { Steps } from 'antd';

function EditItem({ checkboxItem1, setCheckboxItem1, current, maxLevel, setmaxLevel, note, setNote, qty, setQty, handeAddtoCart, databyID, setEdit }) {
  const { editData, setCurrent, selectVariation, setSelectVariation, setProductDetails } = useContext(userContext)
  const [editSelection, setEditSelection] = useState([]);

  const handleCheckboxItem1 = (item) => {
    if (editSelection.length > 0) {
      setEditSelection([]);
    }
    setSelectVariation(item)
  }

  const handleNext = () => {
    setCurrent(current + 1);
    setCheckboxItem1([...checkboxItem1, selectVariation]);
    setSelectVariation(null)

  }

  const handleBack = () => {
    setCurrent(current - 1);
    setCheckboxItem1([])
  }


  const handleMax = () => {
    setmaxLevel(
      databyID.reduce((max, item) => {
        return item.level > max ? item.level : max
      }, 0)
    )
  }

  useEffect(() => {
    handleMax();
    setEditSelection(editData.selectVariation)
    setQty(editData.qty)
    setNote(editData.note)
    const items = databyID.filter((item) => item.level === current);
    if (items.length === 1) {
      setSelectVariation(items[0]);
    }
  }, [current, databyID, editData]);
  console.log("checkboxItem1", checkboxItem1);

  const steps = Array.from({ length: maxLevel + 1 }, (_, index) => index + 1);

  if (maxLevel > 0) {
    return (
      <>
        <Loading />
        <Steps current={current} items={steps} />
        <div className='salad'>
          {maxLevel >= (current + 1) ?
            <>
              <div style={{ display: "flex", justifyContent: "space-between", margin: "20px 0" }}>
                {current != 0 ?
                  <button style={{ background: checkboxItem1 ? "#4e35b1" : "#f4f4f6", color: checkboxItem1 ? "#fff" : "#4e35b1" }} onClick={() => handleBack()}>
                    Prev
                  </button>
                  : ""}
                <div></div>
                <div>
                  <button
                    disabled={selectVariation === null}
                    style={{
                      background: selectVariation != null ? "#4e35b1" : "#f4f4f6",
                      color: selectVariation != null ? "#fff" : "#4e35b1"
                    }}
                    onClick={() => handleNext()}
                  >
                    Next
                  </button>
                </div>
              </div>

              <p className='selectDesc'>Please Select 1 item <span>(Required)</span></p>

              <div className='item-outer custom-scrollbar'>
                {databyID.filter((item) => item.level === (current + 1)).map((i, index) => (
                  <label key={index} className="item">
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxItem1(i)}
                      checked={editSelection.some((item) => item.id === i.id) || selectVariation === i}
                    />
                    <span style={{ marginLeft: "20px" }}>
                      {i.name} {i.price === "" || i.price === 0 ? "" : `[$${i.price}]`}
                    </span>
                  </label>
                ))}

              </div>
            </>
            : (
              <>
                <div style={{ margin: "20px 0" }}>
                  <button style={{ background: checkboxItem1 ? "#4e35b1" : "#f4f4f6", color: checkboxItem1 ? "#fff" : "#4e35b1" }} onClick={() => handleBack()}>
                    Prev
                  </button>
                </div>
                <div className='note-outer'>
                  <span>Notes</span>
                  <textarea className='note' value={note} onChange={(e) => setNote(e.target.value)}></textarea>
                </div>
                <div className='qty-inc-dec'>
                  <div onClick={() => { if (qty > 1) { setQty(qty - 1) } }}>-</div>
                  <input type="text" value={qty} readOnly onChange={(e) => setQty(e.target.value)} />
                  <div onClick={() => { setQty(qty + 1); }}>+</div>
                </div>
              </>
            )}
          <div className='model-footer'>
            <button onClick={() => { setEdit(false); setCurrent(0);setQty(1); setCheckboxItem1([]) }} className='cancle'>CANCEL</button>
            <button
              disabled={maxLevel >= (current + 1)}
              style={{
                background: maxLevel >= (current + 1) ? "#f4f4f6" : "#4e35b1",
                color: maxLevel >= (current + 1) ? "#4e35b1" : "#fff"
              }}
              onClick={handeAddtoCart}
            >
              ADD TO CART
            </button>
          </div>
        </div>
      </>
    )
  }
  else if (maxLevel === 0) {
    return (
      <>
        <div className='salad'>
          {current === 0 ?
            <>
              <div className='note-outer'>
                <span>Notes</span>
                <textarea className='note' value={note} onChange={(e) => setNote(e.target.value)}></textarea>
              </div>

              <div className='qty-inc-dec'>
                <div onClick={() => { if (qty > 1) { setQty(qty - 1) } }}>-</div>
                <input type="text" value={qty} readOnly onChange={(e) => setQty(e.target.value)} />
                <div onClick={() => setQty(qty + 1)}>+</div>
              </div>
            </>
            : ""}

          <div className='model-footer'>
            <button onClick={() => { setEdit(false); setCurrent(0); setmaxLevel(0); setCheckboxItem1([]); }} className='cancle'>CANCEL</button>

            <button disabled={current != 0} style={{ background: current === 0 ? "#4e35b1" : "#f4f4f6", color: current === 0 ? "#fff" : "#4e35b1" }} onClick={handeAddtoCart}>ADD TO CART</button>
          </div>
        </div>
      </>
    )
  }
}

export default EditItem;
import React, { useContext, useEffect, useState } from 'react'
import NoImage from '../../assets/images/NoImage.png'
import Loading from '../loading/Loading';
import EditItem from './EditItem';
import { Modal } from 'antd'
import EditCustomize from './EditCustomize';
import { userContext } from '../../context/Usercontext';
import axios from 'axios';
import EditPizza from './EditPizza';

function Edit({ editData, setEdit, index }) {
    const { checkboxItem1, setCheckboxItem1, current, setCurrent, maxLevel, setmaxLevel, qty, size, crust, spice, pizzaName, setCartData, cartData, setSelectVariation, setSize, setNote, note, setQty, setSelectedItems, setIsLoading, pdetail } = useContext(userContext)

    const [editCustomize, setEditCustomize] = useState(false)
    const [databyID, setDatabyID] = useState([]);
    const [editModify, setEditModify] = useState([]);


    const handeAddtoCart = () => {
        setmaxLevel(0)
        const params = {
            produtDetail: editData.produtDetail,
            qty: qty,
            note: note,
            selectVariation: checkboxItem1,
            pSize: size,
            spice: spice,
            pCrust: crust,
            pizzaName: pizzaName,
            price: editData.produtDetail.isPizza === 1 ?
                Number(((size.sizePriceX1 + crust.modifierCost + checkboxItem1.reduce((total, item) => total + item.pizzaModifierPrice, 0)) * qty).toFixed(2))
                :
                Number(((editData.produtDetail.price + checkboxItem1.reduce((total, item) => total + item.price, 0)) * qty).toFixed(2))
        }

        console.log("edit params", params);


        const updateArray = [...cartData]
        updateArray[index] = params
        setCartData(updateArray)
        localStorage.setItem('cartData', JSON.stringify(updateArray))

        // Click cart and reset all variables

        setCurrent(0)
        setSelectVariation(null)
        setEdit(false)
        setNote("")
        setQty(1)
        setSize(editData.pSize)
        setSelectedItems([])
        setCheckboxItem1([])
    }


    const getProductItem = async () => {
        try {
            setIsLoading(true)
            const responce = await axios.get(`${process.env.REACT_APP_URL}/api/Variation/ByProductId?productId=${pdetail.productId}`);
            console.log("get product data ", responce);
            if (responce.status === 200) {
                setDatabyID(responce.data)
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getProductItem();
    }, [pdetail.productId])

    return (
        <>
            <Loading />
            <div className='product-details-outer'>
                <div className='product-detail-inner'>
                    <div className='about-product'>
                        <h3 className='pname'>{editData.produtDetail.productName}</h3>
                        {editData.produtDetail.pricedesc === "" || editData.produtDetail.pricedesc === "0" ?
                            <h6 className='price'>${editData.produtDetail.price}</h6>
                            : ""}
                    </div>
                    <div className='product-img'>
                        <img src={NoImage} alt="" />
                    </div>
                </div>
                {editData.produtDetail.isPizza === 1 || editData.produtDetail.isCreateYourOwn === 1 || editData.produtDetail.isDeal === 1 ?
                    <EditPizza {...{ editData, setEditCustomize, setEdit, handeAddtoCart, setSize, note, setNote, qty, setQty, pdetail, size, crust, spice, pizzaName, editModify, setEditModify }} />
                    :
                    <EditItem {...{ checkboxItem1, setCheckboxItem1, current, maxLevel, setmaxLevel, note, setNote, qty, setQty, handeAddtoCart, databyID, setEdit }} />
                }
            </div>



            {/* PIZZA */}
            {editData.produtDetail.isPizza === 1 ?
                <Modal open={editCustomize} onCancel={() => setEditCustomize(false)} width={500} height={600}>
                    <EditCustomize {...{ setEditCustomize, pdetail, editData, editModify, setEditModify }} />
                </Modal>
                : ""}
        </>
    )
}

export default Edit;
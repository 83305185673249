import React, { useEffect, useState } from 'react'
import noImage from '../../assets/images/NoImage.png'
import axios from 'axios';
import Pagination from './Pagination';

function ItemDetail() {
    const [data, setData] = useState([]);
    const [currentPage, setcurrentPage] = useState(1);
    const [rowPerPage, setrowPerPage] = useState(10);
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        axios.get("https://jsonplaceholder.typicode.com/todos").then((res) => {
            console.log(res);
            setData(res.data);
        });
    }, []);

    const handleChangeRowsPerPage = (event) => {
        setrowPerPage(Number(event.target.value));
        setcurrentPage(1);
    };

    const totalPage = Math.ceil(data.length / rowPerPage);
    const startIndex = (currentPage - 1) * rowPerPage;
    const endIndex = startIndex + rowPerPage;

    // Sorting function for the 'title' property
    const sortData = (data, order) => {
        const sortedData = [...data];
        sortedData.sort((a, b) => {
            const titleA = a.title.toLowerCase();
            const titleB = b.title.toLowerCase();

            if (order === 'asc') {
                return titleA.localeCompare(titleB);
            } else {
                return titleB.localeCompare(titleA);
            }
        });
        return sortedData;
    };

    const sortedData = sortData(data, sortOrder);

    // Filtering function based on search term
    const filteredData = sortedData.filter((item) =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const currentRow = filteredData.slice(startIndex, endIndex);

    const handlePageChange = (page) => {
        setcurrentPage(page);
    };

    const handleSort = () => {
        setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setcurrentPage(1);
    };
    return (
        <>
            <div className='itemDetail-outer'>
                <div className='row'>
                    <div className='item col-lg-6'>
                        <div className='item-name'>Department Name</div>
                        <div>
                            <select>
                                <option>Select Option</option>
                                <option>PIZZA</option>
                                <option>CALZON</option>
                                <option>PASTA</option>
                            </select>
                        </div>
                    </div>
                    <div className='item col-lg-6'>
                        <div className='item-name'>Item Name</div>
                        <div>
                            <input type="text" />
                        </div>
                    </div>
                    <div className='item col-lg-6'>
                        <div className='item-name'>Item Description</div>
                        <div>
                            <textarea></textarea>
                        </div>
                    </div>
                    <div className='item col-lg-6'>
                        <div className='item-name'>Price</div>
                        <div>
                            <input type="text" />
                        </div>
                    </div>
                    <div className='item col-lg-6'>
                        <div className='item-name'>Item Image</div>
                        <div className=' row item'>
                            <div className='img'>
                                <img src={noImage} alt="" />
                            </div>
                            <div className='file'>
                                <input type="file" />
                            </div>
                        </div>
                    </div>
                    <div className='item col-lg-6'>
                        <div className='item-name'>Display Price</div>
                        <div>
                            <input type="text" />
                        </div>
                    </div>
                    <div className='item col-lg-6'>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <button className='save'>Save</button>
                            <button className='clear'>Clear</button>
                        </div>
                    </div>
                </div>


                <div className='search-outer' style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                    <span>Search : </span>
                    <input type="text" className='search' value={searchTerm} onChange={handleSearchChange} />
                </div>
                <div className='data-table-outer' style={{ borderRadius: "10px !importent" }}>
                    <table style={{ border: "1px solid #d5d5d5" }} cellPadding={0} cellSpacing={0} width="100%" >
                        <thead>
                            <tr>
                                <th onClick={handleSort} style={{ width: "15%", padding: "10px", border: "1px solid #d5d5d5", cursor: 'pointer' }}>
                                    Department {sortOrder === 'asc' ? '▲' : '▼'}
                                </th>
                                <th onClick={handleSort} style={{ width: "45%", padding: "10px", border: "1px solid #d5d5d5", cursor: 'pointer' }}>
                                    Item Name {sortOrder === 'asc' ? '▲' : '▼'}
                                </th>
                                <th onClick={handleSort} style={{ width: "20%", padding: "10px", border: "1px solid #d5d5d5", cursor: 'pointer' }}>
                                    Item Description {sortOrder === 'asc' ? '▲' : '▼'}
                                </th>
                                <th onClick={handleSort} style={{ width: "10%", padding: "10px", border: "1px solid #d5d5d5", cursor: 'pointer' }}>
                                    Price {sortOrder === 'asc' ? '▲' : '▼'}
                                </th>
                                <th style={{ width: "5%", padding: "10px", border: "1px solid #d5d5d5" }}></th>
                                <th style={{ width: "5%", padding: "10px", border: "1px solid #d5d5d5" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentRow.map((item) => (
                                <tr key={item.id}>
                                    <td style={{ width: "15%", padding: "5px 10px", border: "1px solid #d5d5d5" }}>{item.title}</td>
                                    <td style={{ width: "45%", padding: "5px 10px", border: "1px solid #d5d5d5" }}>{item.title}</td>
                                    <td style={{ width: "20%", padding: "5px 10px", border: "1px solid #d5d5d5" }}>{item.title}</td>
                                    <td style={{ width: "10%", padding: "5px 10px", border: "1px solid #d5d5d5" }}>{item.title}</td>
                                    <td className='icon' style={{ width: "5%", padding: "5px 10px", border: "1px solid #d5d5d5", textAlign: "center" }}>
                                        <i className="fa-solid fa-pen"></i>
                                    </td>
                                    <td className='icon' style={{ width: "5%", padding: "5px 10px", border: "1px solid #d5d5d5", textAlign: "center" }}>
                                        <i className="fa-solid fa-trash"></i>
                                    </td>
                                </tr>
                            ))}
                            <tr style={{ padding: "15px", width: "100%" }}>
                                <td colSpan={3}>
                                    <Pagination totalPage={totalPage} currentPage={currentPage} onPageChange={handlePageChange} />
                                </td>
                                <td className='pagination-main' colSpan={3} style={{ width: "100%", padding: "15px" }}>
                                    <span>Rows per page:</span>
                                    <select className='selection' onChange={handleChangeRowsPerPage} value={rowPerPage}>
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={20}>100</option>
                                    </select>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default ItemDetail;
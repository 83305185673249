import Login from './Login/Login';
import Changepassword from './components/Changepassword';
import Customerdetail from './components/Customerdetail';
import HeaderState from './context/Usercontext';
import Layout from './layout/Layout';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <>
        {/* <Layout /> */}
        <HeaderState>
          <BrowserRouter>
            <Routes>
              <Route path="/*" element={<Layout />} />
              <Route path="/login" element={<Login/>} />
              <Route path="/chnagepassword" element={<Changepassword />} />
              <Route path="/customerdetail" element={<Customerdetail />} />
            </Routes>
          </BrowserRouter>
        </HeaderState>
      </>
    </>
  );
}

export default App;

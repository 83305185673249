import React, { useContext, useEffect, useState } from 'react'
import Loading from '../loading/Loading';
import axios from 'axios';
import { userContext } from '../../context/Usercontext';

function EditCustomize({ setEditCustomize, pdetail, editData, editModify, setEditModify }) {
  const [modify, setModify] = useState([]);
  const [modifyname, setModifyname] = useState("VEGGIE")
  const { setCheckboxItem1, checkboxItem1, setIsLoading } = useContext(userContext)

  const handleCheckboxChange = (modifier) => {

    if (editModify.length > 0) {
      setEditModify([])
    }

    const isModifierSelected = checkboxItem1.some((selected) => selected.pizzaModifierId === modifier.pizzaModifierId);

    const updatedModifiers = isModifierSelected
      ? checkboxItem1.filter((selected) => selected.pizzaModifierId !== modifier.pizzaModifierId)
      : [...checkboxItem1, modifier];
    setCheckboxItem1(updatedModifiers);

  };

  const handleAddToCartClick = () => {
    setCheckboxItem1(checkboxItem1)
    setEditCustomize(false)
  }

  const fatchData = async () => {
    try {
      setIsLoading(true)
      const responce = await axios.get(`${process.env.REACT_APP_URL}/api/Modifier/ModifierCategories?productId=${pdetail.productId}&sizeId=1`);
      console.log(responce);
      if (responce.status === 200) {
        setModify(responce.data)
      }
    }
    catch (err) {
      console.log(err);
    }
    finally {
      setIsLoading(false);
    }
  }
  console.log("checkboxItem1", checkboxItem1);

  useEffect(() => {
    fatchData();
    setEditModify(editData.selectVariation)
    setCheckboxItem1(editData.selectVariation)
  }, [editData.selectVariation])

  return (
    <>
      <Loading />
      <div className='customize-outer'>
        <ul className='customize-inner'>
          {modify.map((parentItem) => (
            <li
              key={parentItem.modifierName}
              className='links'
              onClick={() => setModifyname(parentItem.modifierName)}
            >
              {parentItem.modifierName}
            </li>
          ))}
        </ul>

        <div className='chackbox-seletion custom-scrollbar'>
          {modify.map((item) =>
            item.modifierName === modifyname ? (
              item.modifiers.map((modifier, index) => (
                <label key={index} className="item">
                  <input
                    type="checkbox"
                    checked={editModify.some((selected) => selected.pizzaModifierId === modifier.pizzaModifierId) || checkboxItem1.some((selected) => selected.pizzaModifierId === modifier.pizzaModifierId)}
                    onChange={() => handleCheckboxChange(modifier)}
                  />
                  <span style={{ marginLeft: "20px" }}>
                    {modifier.pizzaModifierName} {modifier.pizzaModifierPrice === "" || modifier.pizzaModifierPrice === 0 ? "" : (modifier.pizzaModifierPrice)}
                  </span>
                </label>
              ))
            ) : null
          )}

        </div>

        <div className='customize-btn'>
          <button onClick={() => { setEditCustomize(false) }}>CANCEL</button>
          <button onClick={handleAddToCartClick}>ADD TO CART</button>
        </div>
      </div>
    </>
  )
}

export default EditCustomize;
import React, { useContext, useRef, useState } from 'react'
import { Steps, Spin, Col, Row } from 'antd';
import '../css/checkout.css'
import Box from '@mui/material/Box';
import InputField from './Input';
import TextField from '@mui/material/TextField';
import { userContext } from '../context/Usercontext';
import axios from 'axios';
import TotalBill from './Item Details/TotalBill/TotalBill';
import { DatePicker, Space } from 'antd';
import { Input, TimePicker, Form } from 'antd';
import { Tabs } from 'antd';
import dayjs from 'dayjs';
import GoogleMaps from './AutoComplateGoogleMap';
import { LoadingOutlined } from '@ant-design/icons';


function CheckOut({ totalPrice, gsttotal, pltTotal, setCheckOut, isModalOpen, setIsModalOpen }) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { TabPane } = Tabs;
  const steps = [
    {
      title: 'Contact Info'
    },
    {
      title: 'Payment'
    }
  ];
  const [time, setTime] = useState(dayjs())
  const [date, setDate] = useState(dayjs())
  const [adress, setAdress] = useState()
  const { orderType } = useContext(userContext)
  const formRef = useRef(null);
  const reqObj = {
    buzzerno: "",
    clientId: 0,
    date: dayjs(date.$d).toLocaleString('DD:MM:YYY'),
    email: "",
    firstname: "",
    id: 0,
    lastname: "",
    orderDesc: "",
    orderType: orderType,
    phoneNo: "",
    roomno: "",
    suitno: "",
    time: dayjs(time.$d).format('HH:mm a')
  }
  const [loading, setLoading] = useState(false);
  const [tip, setTip] = useState(0);
  const [clientData, setclientData] = useState()
  const [data, setData] = useState(reqObj)
  const [payment, setPayment] = useState("")


  // GET VALUE FROM INPUT
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  //  TIP START
  const handlePercentageButtonClick = (e) => {
    const percentage = Number(e.target.value);
    const calculatedTip = (totalPrice * percentage) / 100;
    setTip(calculatedTip);
  };
  const handleInputChange = (e) => {
    const enteredTip = Number(e.target.value);
    setTip(enteredTip);
  };
  var handleCustomer = () => {
    axios.get(`${process.env.REACT_APP_URL}/api/Customer/ByPhone?phoneNo=${data.phoneNo}`)
      .then((res) => {
        console.log("res : ", res);
        if (res.status === 200) {
          setCurrent(current + 1)
          setclientData(res.data)
        }
      })
      .catch((res) => {
        console.log(res);
        if (res.response.status === 404) {
          axios.post(`${process.env.REACT_APP_URL}/api/Customer`, data)
            .then((res) => {
              console.log("register", res);
              if (res.status === 200) {
                setCurrent(current + 1)
                setclientData(res.data)
              }
            })
            .catch((res) => {
              console.log(res);
            })
        }
      })
  }

  const [current, setCurrent] = useState(0);

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const cutomerRegistred = (values) => {
    setLoading(true);
    setTimeout(() => {
      console.log('Regestered', values);
      setLoading(false);
      formRef.current.resetFields();
      localStorage.setItem('registeredUser', JSON.stringify(values));
    }, 2000);
  }



  const oncustomerLogin = (values) => {
    const { email, password } = values;
    const customerdata = JSON.parse(localStorage.getItem('registeredUser'));
    if (customerdata && customerdata.email === email && customerdata.password === password) {
      setLoading(true);
      setTimeout(() => {
       console.log('Login Suceesfull');
        setLoading(false);
        formRef.current.resetFields();
      }, 2000);
    } else {
      console.log("Invalid credentiail!");
    }
  };

  return (

    <>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Registered Customer" key="1">
          <div className="customer-login-outer">
            <Form
              name="login-form"
              initialValues={{ remember: true }}
              layout="vertical"
              onFinish={oncustomerLogin}
              ref={formRef}
            >
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Please input your email!' }]}
              >
                <Input placeholder="Enter your email" size="large" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password placeholder="Enter your password" size="large" />
              </Form.Item>

              <div className="d-flex justify-content-center" style={{ margin: '20px 0' }}>
                <Spin spinning={loading} indicator={antIcon}>
                  <Form.Item>
                    <button type="submit" style={{ marginRight: '10px' }}>
                      Login
                    </button>
                  </Form.Item>
                </Spin>
                <Form.Item>
                  <button type="button" style={{ marginLeft: '10px' }}>
                    Cancel
                  </button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </TabPane>
        <TabPane tab="New Registration" key="2">
          <Form onFinish={cutomerRegistred} ref={formRef}>
            <Row gutter={[16, 16]}>
              <Col xs={12} sm={12}>
                <Form.Item name="firstName"
                  rules={[{ required: true, message: 'Please input your first name!' }]}>
                  <Input placeholder="First Name" size="large" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12}>
                <Form.Item name="lastName"
                  rules={[{ required: true, message: 'Please input your last name!' }]}>
                  <Input placeholder="Last Name" size="large" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12}>
                <Form.Item name="email"
                  rules={[{ required: true, type: 'email', message: 'Please input a valid email address!' }]}>
                  <Input placeholder="Email" size="large" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12}>
                <Form.Item name="phoneNumber"
                  rules={[{ required: true, message: 'Please input your phone number!' }]}>
                  <Input placeholder="Phone Number" size="large" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12}>
                <Form.Item name="password"
                  rules={[{ required: true, message: 'Please input your password!' }]}>
                  <Input.Password placeholder="Password" size="large" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12}>
                <Form.Item
                  name="confirmPassword"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    { required: true, message: 'Please confirm your password!' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('The two passwords do not match!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Confirm Password" size="large" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item name="address"
                  rules={[{ required: true, message: 'Please input your address!' }]}>
                  <Input.TextArea placeholder="Address" size="large" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <div className="d-flex justify-content-center" style={{ margin: '20px 0' }}>
                  <Spin spinning={loading} indicator={antIcon}>
                    <Form.Item>
                      <button type="submit" style={{ marginRight: '10px' }}>
                        Register
                      </button>
                    </Form.Item>
                  </Spin>
                  <Form.Item>
                    <button type="button" style={{ marginLeft: '10px' }}>
                      Cancel
                    </button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </TabPane>

        <TabPane tab="Checkout" key="3">
          <h4>Checkout</h4>
          <Steps current={current} items={items} />
          <div className='order-type'>
            <h6>Order Type : {orderType}</h6>
          </div>


          {/* ORDER TYPE */}
          <Form method="GET" onFinish={handleCustomer} >
            {current === 1 ? (
              orderType === "TakeOut" ?
                <div className='pickup'>

                  <h6>Phone No *</h6>
                  <Form.Item
                    name="phoneNo"
                    defaultValue={data.phoneNo}
                    rules={[
                      {
                        required: true,
                        message: "Please Input Phone No."
                      },
                      {
                        whitespace: true,
                        message: "Please Input Phone No."
                      }
                    ]} hasFeedback>
                    <Input maxLength={10} defaultValue={data.phoneNo} placeholder="Phone No *" name="phoneNo" onChange={handleChange} style={{ height: "50px" }} />
                  </Form.Item>

                  <h6>Email</h6>
                  <InputField {...{ placeHolder: "Email ", name: "email", require: false, whitespace: false, message: "Please Input Email", getValue: handleChange }} />

                  <h6>First Name *</h6>
                  <Form.Item
                    name="firstname"
                    rules={[
                      {
                        required: true,
                        message: "Please Input First Name"
                      },
                      {
                        whitespace: true,
                        message: "Please Input First Name"
                      }
                    ]} hasFeedback>
                    <Input maxLength={10} placeholder="First Name *" name="firstname" onChange={handleChange} style={{ height: "50px" }} />
                  </Form.Item>

                  <h6>Last Name</h6>
                  <InputField {...{ placeHolder: "Last name", name: "lastname", require: false, whitespace: false, message: "Last Name", getValue: handleChange }} />

                  <h6>Date</h6>
                  <Form.Item name="date" hasFeedback >
                    <Space style={{ width: "100%" }}>
                      <DatePicker name='date' value={date} onChange={(date) => setDate(date)} style={{ height: "50px", width: "100%" }} />
                    </Space>
                  </Form.Item>

                  <h6>Time</h6>
                  <Space style={{ width: "100%" }}>
                    <TimePicker style={{ height: "50px", width: "100%" }} use12Hours value={time} format="h:mm a" onChange={(time, timeString) => setTime(time, timeString)} />
                  </Space>

                  <p className='desc'>By proceeding, you consent to get calls, SMS messages, or emails including by automated means, from RANGER POS and its affiliates to the number and email provided.</p>
                </div>
                :
                <div className='delivery'>
                  <div className="input-outer">
                    <div className='input'>

                      <h6>Suit No</h6>
                      <InputField {...{
                        placeHolder: "Suit no.", name: "suitno", require: false, whitespace: false,
                        message: "Please Input Suit no.", getValue: handleChange
                      }} />
                    </div>
                    <div className='input'>
                      <h6>Buzzer No</h6>
                      <InputField {...{
                        placeHolder: "Buzzer no.", name: "buzzerno", require: false, whitespace: false,
                        message: "Please Input Buzzer no.", getValue: handleChange
                      }} />
                    </div>
                    <div className='input'>
                      <h6>Room No</h6>
                      <InputField {...{ placeHolder: "Room no.", name: "roomno", require: false, whitespace: false, message: "Please Input Room no.", getValue: handleChange }} />
                    </div>
                    <div className='location my-[15px]'>
                      {/* <h6>Location</h6> */}
                      <Box sx={{ '& > :not(style)': { m: "8px 0", width: '100%' }, }}
                       autoComplete="off" >
                        <GoogleMaps {...{ adress, setAdress }} />
                      </Box>
                    </div>

                    <div className='input'>
                      <h6>Phone No *</h6>
                      <Form.Item
                        name="phoneNo"
                        rules={[
                          {
                            required: true,
                            message: "Please Input Phone No."
                          },
                          {
                            whitespace: true,
                            message: "Please Input Phone No."
                          }
                        ]} hasFeedback>
                        <Input maxLength={10} placeholder="Phone No *" name="phoneNo" onChange={handleChange} style={{ height: "50px" }} />
                      </Form.Item>
                    </div>

                    <div className='input'>
                      <h6>Email</h6>
                      <InputField {...{ placeHolder: "Email", name: "email", require: false, message: "Please Input Email", getValue: handleChange }} />
                    </div>

                    <div className='input'>
                      <h6>First Name *</h6>
                      <Form.Item
                        name="firstname"
                        rules={[
                          {
                            required: true,
                            message: "Please Input First Name"
                          },
                          {
                            whitespace: true,
                            message: "Please Input First Name"
                          }
                        ]} hasFeedback>
                        <Input maxLength={10} placeholder="First Name *" name="firstname" onChange={handleChange} style={{ height: "50px" }} />
                      </Form.Item>
                    </div>

                    <div className='input'>
                      <h6>Last Name</h6>
                      <InputField {...{ placeHolder: "Last name", name: "lastname", require: false, message: "Last Name", getValue: handleChange }} />
                    </div>

                    <div className='input'>
                      <h6>Date</h6>
                      <Form.Item name="date" hasFeedback >
                        <Space style={{ width: "100%" }}>
                          <DatePicker name='date' value={date} onChange={(date) => setDate(date)} style={{ height: "50px", width: "100%" }} />
                        </Space>
                      </Form.Item>
                    </div>

                    <div className='input'>
                      <h6>Time</h6>
                      <Space style={{ width: "100%", marginBottom: "24px" }}>
                        <TimePicker style={{ height: "50px", width: "100%" }} use12Hours value={time} format="h:mm a" onChange={(time, timeString) => setTime(time, timeString)} />
                      </Space>
                    </div>

                    <div className='note'>
                      <Box sx={{ '& > :not(style)': { m: "8px 0", width: '100%' }, }} autoComplete="off" >
                        <TextField
                          id="outlined-multiline-static"
                          label="Note"
                          multiline
                          disabled
                          rows={4}
                          defaultValue="Approximate 60 Min. after placed your order"
                        />
                      </Box>
                    </div>
                    <p className='desc'>By proceeding, you consent to get calls, SMS messages, or emails including by automated means, from RANGER POS and its affiliates to the number and email provided.</p>

                  </div>
                </div>
            )
              : (
                <TotalBill {...{ totalPrice, tip, handlePercentageButtonClick, handleInputChange, setPayment, payment, clientData, data, orderType, gsttotal, pltTotal, setCheckOut, setCurrent, reqObj, setData, isModalOpen, setIsModalOpen }} />
              )}

            <div style={{ marginTop: 24, display: 'flex', justifyContent: 'space-between' }} >
              {
                current === 1 ?
                  <button type="primary" style={{ margin: '0 8px' }} onClick={() => {
                    if (current > 0) {
                      setCurrent(current - 1);
                    }
                  }}>Back </button>
                  : ""
              }
              <div></div>

              {current < steps.length - 1 && (
                <button type="submit">
                  Next
                </button>
              )}

            </div>
          </Form >
        </TabPane>
      </Tabs >

    </>
  )
}

export default CheckOut;
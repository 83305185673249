import React, { useContext, useState } from 'react'
import '../css/billing.css'
import { userContext } from '../context/Usercontext';
import { Modal } from 'antd';
import CheckOut from './CheckOut';
import Edit from './update/Edit';
import Success from './success/Success';


function Billing() {

    const { cart, setCart, taxes, edit, setEdit, cartData, setCartData, orderType, setOrderType, setPdetail, editData, setEditData, setQty, forget, setForget } = useContext(userContext)
    const [checkout, setCheckOut] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [index, setIndex] = useState(null)

    let gsttotal = "0.00"
    let pltTotal = "0.00"
    const totalPrice = cartData.reduce((total, item) => {
        let itemTotal = item.price || 0;
        if (Array.isArray(item.variationsPrice)) {
            itemTotal += (item.variationsPrice.reduce((sum, price) => sum + price, 0))


        }
        if (item.produtDetail.taxId1 === 1) {
            const itemtotal = item.price;
            const gstTaxRate = taxes.find((tax) => tax.id === 1)?.rate;
            if (gstTaxRate) {
                gsttotal = (Number(gsttotal) + (itemtotal * gstTaxRate) / 100).toFixed(2);

                if (parseInt(gsttotal.toString().charAt(4)) >= 5) {
                    gsttotal = (parseFloat(gsttotal)).toFixed(2);
                }
            }
        }
        if (item.produtDetail.taxId2 === 1) {
            const itemtotal = item.price;
            const plttaxtrate = taxes.find((tax) => tax.id === 2)?.rate;
            if (plttaxtrate) {
                pltTotal = (Number(pltTotal) + (itemtotal * plttaxtrate) / 100).toFixed(2);

                if (parseInt(pltTotal.toString().charAt(4)) >= 5) {
                    pltTotal = (parseFloat(pltTotal) + 0.01).toFixed(2);
                }
            }
        }
        return total + itemTotal;
    }, 0);

    const handleDelete = (index) => {
        console.log("index", index);
        const newArray = [...cartData]
        newArray.splice(index, 1)
        setCartData(newArray)
        localStorage.setItem('cartData', JSON.stringify(newArray))
    }

    const handleUpdate = (item, index) => {
        setIndex(index)
        setPdetail(item.produtDetail)
        setEditData(item)
        setEdit(true)
    }

    // setTimeout(() => {
    //     setIsModalOpen(false);
    // }, 10000);

    return (
        <>
            <div className='bill-outer'>
                <div className='bill-inner'>
                    <h3 className='title'>Cart</h3>
                    <div className='d-flex justify-content-around my_main_radio'>
                        <div className='ordertype-select'>
                            <label onChange={(e) => setOrderType(e.target.value)}>
                                <input type="radio" value="TakeOut" checked={orderType === "TakeOut"} /> Take Out
                            </label>
                        </div>
                        <div className='order-type'>
                            <label onChange={(e) => setOrderType(e.target.value)}>
                                <input type="radio" value="Delivery" checked={orderType === "Delivery"} /> Delivery
                            </label>
                        </div>
                    </div>
                </div>
                <div className='table'>
                    <div className='header-name'>
                        <div style={{ width: '40%' }} className='pname'>Product</div>
                        <div style={{ width: '15%', textAlign: "center" }} className='qty'>Qty</div>
                        <div style={{ width: '18%', textAlign: "center" }} className='total'>Total</div>
                        <div style={{ width: '10%' }} className='delete'></div>
                        <div style={{ width: '10%' }} className='Update'></div>
                    </div>
                    <div className={cartData.length > 0 ? `table-body custom-scrollbar` : ""}>
                        {cartData.map((item, index) => (
                            <div className='products' key={index}>
                                <div style={{ width: '40%', fontSize: "13px", padding: '10px 0' }}>

                                    <div style={{ fontSize: "13px", fontWeight: "600" }}>
                                        {index + 1}. {item.produtDetail && item.produtDetail.productName}
                                        {item.produtDetail.isPizza === 1 && item.produtDetail.isDeal === 0 ? `[${item.pSize.sizeName} - $${item.pSize.sizePriceX1}] - [${item.pCrust.modifierName}]` : ""}
                                        {item.produtDetail.isPizza === 1 && item.produtDetail.isDeal === 1 ? `[${item.pSize.sizeName} -$${item.pSize.sizePriceX1}] - [${item.spice}]` : ""}
                                    </div>
                                    {item.produtDetail.isPizza === 1 && item.produtDetail.isDeal === 1 ?
                                        <div>{item.pizzaName.productName}</div>
                                        : ""
                                    }

                                    {item.produtDetail.isPizza === 1 ?
                                        item.selectVariation && item.selectVariation.map((res, i) => (
                                            <div key={i} style={{ padding: "0 10px" }}> * {res.pizzaModifierName} - ${res.pizzaModifierPrice}</div>
                                        ))
                                        :
                                        item.selectVariation && item.selectVariation.map((res, i) => (
                                            <div key={i} style={{ padding: "0 10px" }}> * {res.name}  {res.price === 0 || res.price === "" ? "" : `[$${res.price}]`} </div>
                                        ))}
                                    {item.note !== "" ? <div style={{ fontSize: "13px" }}>**{item.note}</div> : ""}
                                </div>
                                <div style={{ width: '15%', padding: '10px 0', textAlign: "center" }}>{item.qty}</div>
                                <div style={{ width: '18%', padding: '10px 0', textAlign: "center" }}>{item.price === null ? "NAN" : (item.price).toFixed(2)}</div>
                                <div style={{ width: '10%' }}>
                                    <button style={{ background: "#fff" }} onClick={() => handleDelete(index)}><i className="fa-solid fa-trash"></i></button>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <button style={{ background: "#fff" }} onClick={() => handleUpdate(item, index)}><i className="fa-regular fa-pen-to-square"></i></button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className=' about-order'>
                        <div className='subtotal'>
                            <div style={{ width: '65%' }}>Subtotal</div>
                            <div style={{ width: '35%', overflowX: "hidden" }}>${(totalPrice).toFixed(2)}</div>
                        </div>

                        <div className='subtotal'>
                            <div style={{ width: '65%' }}>GST </div>
                            <div style={{ width: '35%', overflowX: "hidden" }}>${gsttotal}</div>
                        </div>
                        <div className='subtotal'>
                            <div style={{ width: '65%' }}>PLT </div>
                            <div style={{ width: '35%', overflowX: "hidden" }}>${pltTotal}</div>
                        </div>

                        <div className='subtotal'>
                            <div style={{ width: '65%' }}>Total</div>
                            <div style={{ width: '35%', overflowX: "hidden" }}>${(totalPrice + Number(gsttotal) + Number(pltTotal)).toFixed(2)}</div>
                        </div>
                        <button disabled={cartData.length === 0} className='order' onClick={() => setCheckOut(true)}>Place Order</button>
                    </div>
                </div>

                <Modal open={cart} onCancel={() => setCart(false)} width={500} height={700}>
                    <div className='bill-inner'>
                        <h3 className='title'>Cart</h3>
                        <div className='d-flex justify-content-around my_main_radio'>
                            <div className='ordertype-select'>
                                <label onChange={(e) => setOrderType(e.target.value)}>
                                    <input type="radio" value="TakeOut" checked={orderType === "TakeOut"} /> Take Out
                                </label>
                            </div>
                            <div className='order-type'>
                                <label onChange={(e) => setOrderType(e.target.value)}>
                                    <input type="radio" value="Delivery" checked={orderType === "Delivery"} /> Delivery
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='table'>
                        <div className='header-name'>
                            <div style={{ width: '40%' }} className='pname'>Product</div>
                            <div style={{ width: '15%', textAlign: "center" }} className='qty'>Qty</div>
                            <div style={{ width: '18%', textAlign: "center" }} className='total'>Total</div>
                            <div style={{ width: '10%' }} className='delete'></div>
                            <div style={{ width: '10%' }} className='Update'></div>
                        </div>
                        <div className={cartData.length > 0 ? `table-body custom-scrollbar` : ""}>
                            {cartData.map((item, index) => (
                                <div className='products' key={index}>
                                    <div style={{ width: '40%', fontSize: "13px", padding: '10px 0' }}>

                                        <div style={{ fontSize: "13px", fontWeight: "600" }}>
                                            {index + 1}. {item.produtDetail && item.produtDetail.productName}
                                            {item.produtDetail.isPizza === 1 && item.produtDetail.isDeal === 0 ? `[${item.pSize.sizeName} - $${item.pSize.sizePriceX1}] - [${item.pCrust.modifierName}]` : ""}
                                            {item.produtDetail.isPizza === 1 && item.produtDetail.isDeal === 1 ? `[${item.pSize.sizeName} -$${item.pSize.sizePriceX1}] - [${item.spice}]` : ""}
                                        </div>
                                        {item.produtDetail.isPizza === 1 && item.produtDetail.isDeal === 1 ?
                                            <div>{item.pizzaName.productName}</div>
                                            : ""
                                        }

                                        {item.produtDetail.isPizza === 1 ?
                                            item.selectVariation && item.selectVariation.map((res, i) => (
                                                <div key={i} style={{ padding: "0 10px" }}> * {res.pizzaModifierName} - ${res.pizzaModifierPrice}</div>
                                            ))
                                            :
                                            item.selectVariation && item.selectVariation.map((res, i) => (
                                                <div key={i} style={{ padding: "0 10px" }}> * {res.name}  {res.price === 0 || res.price === "" ? "" : `[$${res.price}]`} </div>
                                            ))}
                                        {item.note !== "" ? <div style={{ fontSize: "13px" }}>**{item.note}</div> : ""}
                                    </div>
                                    <div style={{ width: '15%', padding: '10px 0', textAlign: "center" }}>{item.qty}</div>
                                    <div style={{ width: '18%', padding: '10px 0', textAlign: "center" }}>{item.price === null ? "NAN" : (item.price).toFixed(2)}</div>
                                    <div style={{ width: '10%' }}>
                                        <button style={{ background: "#fff" }} onClick={() => handleDelete(index)}><i className="fa-solid fa-trash"></i></button>
                                    </div>
                                    <div style={{ width: '10%' }}>
                                        <button style={{ background: "#fff" }} onClick={() => handleUpdate(item, index)}><i className="fa-regular fa-pen-to-square"></i></button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className=' about-order'>
                            <div className='subtotal'>
                                <div style={{ width: '65%' }}>Subtotal</div>
                                <div style={{ width: '35%', overflowX: "hidden" }}>${(totalPrice).toFixed(2)}</div>
                            </div>

                            <div className='subtotal'>
                                <div style={{ width: '65%' }}>GST </div>
                                <div style={{ width: '35%', overflowX: "hidden" }}>${gsttotal}</div>
                            </div>
                            <div className='subtotal'>
                                <div style={{ width: '65%' }}>PLT </div>
                                <div style={{ width: '35%', overflowX: "hidden" }}>${pltTotal}</div>
                            </div>

                            <div className='subtotal'>
                                <div style={{ width: '65%' }}>Total</div>
                                <div style={{ width: '35%', overflowX: "hidden" }}>${(totalPrice + Number(gsttotal) + Number(pltTotal)).toFixed(2)}</div>
                            </div>
                            <button disabled={cartData.length === 0} className='order' onClick={() => setCheckOut(true)}>Place Order</button>
                        </div>
                    </div>
                </Modal>

                <Modal className='user-detail' open={checkout} onCancel={() => setCheckOut(false)} width={600} height={500}>
                    <div className='Checkout'>
                        <CheckOut {...{ totalPrice, gsttotal, pltTotal, setCheckOut, isModalOpen, setIsModalOpen }} />
                    </div>
                </Modal>

                <Modal open={edit} onCancel={() => { setEdit(false); setQty(1) }}>
                    <Edit {...{ editData, setEdit, index }} />
                </Modal>

                <Modal open={isModalOpen} onCancel={() => setIsModalOpen(false)}>
                    <Success />
                </Modal>
            </div>
        </>
    )
}

export default Billing;
import React, { useContext, useEffect, useState } from 'react'
import '../../css/admin css/onlineOrder.css'
import axios from 'axios';
import { userContext } from '../../context/Usercontext';

function OnlineOrder() {
    const { cartData, taxes, billData, setCheckOut } = useContext(userContext)
    const [orders, setOrders] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);

    const selectedRowClick = (rowData) => {
        setSelectedRow(rowData)
    }


    let gsttotal = "0.00"
    let pltTotal = "0.00"
    const totalPrice = cartData.reduce((total, item) => {
        let itemTotal = item.price || 0;
        if (Array.isArray(item.variationsPrice)) {
            itemTotal += (item.variationsPrice.reduce((sum, price) => sum + price, 0))


        }
        if (item.produtDetail.taxId1 === 1) {
            const itemtotal = item.price;
            const gstTaxRate = taxes.find((tax) => tax.id === 1)?.rate;
            if (gstTaxRate) {
                gsttotal = (Number(gsttotal) + (itemtotal * gstTaxRate) / 100).toFixed(2);

                if (parseInt(gsttotal.toString().charAt(4)) >= 5) {
                    gsttotal = (parseFloat(gsttotal)).toFixed(2);
                }
            }
        }
        if (item.produtDetail.taxId2 === 1) {
            const itemtotal = item.price;
            const plttaxtrate = taxes.find((tax) => tax.id === 2)?.rate;
            if (plttaxtrate) {
                pltTotal = (Number(pltTotal) + (itemtotal * plttaxtrate) / 100).toFixed(2);

                if (parseInt(pltTotal.toString().charAt(4)) >= 5) {
                    pltTotal = (parseFloat(pltTotal) + 0.01).toFixed(2);
                }
            }
        }
        return total + itemTotal;
    }, 0);



    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL}/api/OrderSubmition/GetAllOrders`);
                setOrders(response.data);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };
        fetchOrders();
    }, []);
    return (
        <>
            <div className='admin-outer'>
                <div className='order-outer'>
                    <div className='row'>
                        <div className="col-lg-3 row">
                            <div className="col-lg-5">
                                Start Date :
                            </div>
                            <div className="col-lg-7">
                                <input className='date' type="date" />
                            </div>
                        </div>
                        <div className="col-lg-3 row">
                            <div className="col-lg-5">
                                End Date :
                            </div>
                            <div className="col-lg-7">
                                <input className='date' type="date" />
                            </div>
                        </div>
                        <div className="col-lg-3 row">
                            <button className='col-lg-6 search'>Search</button>
                            <button className='col-lg-6 print'>Print</button>
                        </div>
                    </div>
                    <div className='row order-table'>
                        <div className="col-lg-6">
                            <div className='data-table-outer' style={{ borderRadius: "10px !importent" }}>
                                <table style={{ border: "1px solid #d5d5d5" }} cellPadding={0} cellSpacing={0} width="100%" >
                                    <thead>
                                        <tr>
                                            <th style={{ width: "100px", padding: "10px", border: "1px solid #d5d5d5", cursor: 'pointer', color: "#fff", background: "#046cf5" }}>
                                                <div>
                                                    Order
                                                </div>
                                            </th>
                                            <th style={{ width: "110px", padding: "10px", border: "1px solid #d5d5d5", cursor: 'pointer', color: "#fff", background: "#046cf5" }}>
                                                Type
                                            </th>
                                            <th style={{ width: "180px", padding: "10px", border: "1px solid #d5d5d5", cursor: 'pointer', color: "#fff", background: "#046cf5" }}>
                                                Status
                                            </th>
                                            <th style={{ width: "125px", padding: "10px", border: "1px solid #d5d5d5", cursor: 'pointer', color: "#fff", background: "#046cf5" }}>
                                                Date
                                            </th>
                                            <th style={{ width: "100px", padding: "10px", border: "1px solid #d5d5d5", color: "#fff", background: "#046cf5" }}>Payable</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders.map((item) => (
                                            <tr key={item.id} onClick={() => selectedRowClick(item)}>
                                                <td style={{ width: "100px", padding: "5px 10px", border: "1px solid #d5d5d5" }}>{item.orderID}</td>
                                                <td style={{ width: "110px", padding: "5px 10px", border: "1px solid #d5d5d5" }}>{item.orderType}</td>
                                                <td style={{ width: "180px", padding: "5px 10px", border: "1px solid #d5d5d5" }}>ORDER ACCEPTED</td>
                                                <td style={{ width: "125px", padding: "5px 10px", border: "1px solid #d5d5d5" }}>{item.onDate}</td>
                                                <td style={{ width: "100px", padding: "5px 10px", border: "1px solid #d5d5d5" }}>{item.subTotal}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-lg-6">

                            {
                                selectedRow && (
                                    <div className='invoice-outer custom-scrollbar'>
                                        <div className='about-company'>
                                            <div>
                                                <h5>RANGER POS</h5>
                                                <p>935 5TH AVE SW</p>
                                                <p>825-735-4000</p>
                                            </div>
                                            <div className='item'>
                                                <h5>{selectedRow.orderType}</h5>
                                                <div>
                                                    <span>INV# : </span>{selectedRow.orderNumber}
                                                </div>
                                                <div>
                                                    <span>Token# : </span>{selectedRow.tokenNumber}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='about-user'>
                                            <div style={{ width: "60%" }} className='item'>
                                                <div>
                                                    <span>Customer:</span> {selectedRow.customer.firstName}
                                                    {selectedRow.customer.lastName}
                                                </div>
                                                <div>
                                                    <span>Phone: </span> {selectedRow.customer.phoneNo}
                                                </div>
                                                <div>
                                                    <span>Address: </span> {selectedRow.customer.ad}
                                                </div>
                                            </div>
                                            <div style={{ width: "40%" }} className='item'>
                                                <div>
                                                    <span>Date:</span> {selectedRow.onDate}
                                                </div>
                                                <div>
                                                    <span>Time:</span> {selectedRow.onTime}
                                                </div>
                                                <div>
                                                    <span>Cashier:</span> Admin
                                                </div>
                                                <div>
                                                    <span>Station Id:</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='order-item'>
                                            <div className='table'>
                                                <div className='header-name'>
                                                    <div style={{ width: '10%', textAlign: "center" }} className='name'>Sr No.</div>
                                                    <div style={{ width: '45%' }} className='name'>Item Name</div>
                                                    <div style={{ width: '25%', textAlign: "center" }} className='name'>Quantity</div>
                                                    <div style={{ width: '20%', textAlign: "center" }} className='name'>Price</div>
                                                </div>


                                                {selectedRow.orderDetail.map((item, index) => (
                                                    <div className='products' key={index}>
                                                        <div style={{ width: '10%', fontSize: "13px", padding: '10px 0' }}>
                                                            <div style={{ fontSize: "13px", fontWeight: "600", textAlign: "center" }}>{index + 1}</div>
                                                        </div>
                                                        <div style={{ width: '45%', padding: '10px 0' }}>
                                                            <div style={{ padding: "0 10px" }}>{item.productCode}</div>
                                                            <div style={{ padding: "0 10px" }}>{item.description}</div>
                                                        </div>
                                                        <div style={{ width: '25%', padding: '10px 0', textAlign: "center" }}>{item.quantity}</div>
                                                        <div style={{ width: '20%', padding: '10px 0', textAlign: "center" }}>{item.subTotal.toFixed(2)}</div>
                                                    </div>
                                                ))}
                                                {cartData.map((item, index) => (
                                                    <div className='products' key={index}>
                                                        <div style={{ width: '15%', fontSize: "13px", padding: '10px 0' }}>
                                                            <div style={{ fontSize: "13px", fontWeight: "600" }}>{index + 1}. {item.produtDetail && item.produtDetail.productName} [{item.pSize.sizeName}]-[{item.pCrust.modifierName}]</div>
                                                            {item.produtDetail.isPizza === 1 ?
                                                                item.selectVariation && item.selectVariation.map((res, i) => (
                                                                    <div key={i} style={{ padding: "0 10px" }}> * {res.pizzaModifierName} - ${res.pizzaModifierPrice}</div>
                                                                ))
                                                                :
                                                                item.selectVariation && item.selectVariation.map((res, i) => (
                                                                    <div key={i} style={{ padding: "0 10px" }}> * {res.name} - ${res.price}</div>
                                                                ))}
                                                            {item.note !== "" ? <div style={{ fontSize: "13px" }}>**{item.note}</div> : ""}
                                                        </div>
                                                        <div style={{ width: '50%', padding: '10px 0' }}>{item.qty}</div>
                                                        <div style={{ width: '15%', padding: '10px 0' }}>{item.qty}</div>
                                                        <div style={{ width: '20%', padding: '10px 0' }}>{(item.price).toFixed(2)}</div>
                                                    </div>
                                                ))}

                                                <div className='subtotal'>
                                                    <div style={{ width: '98%', textAlign: "end" }}>Payment Mode :- {selectedRow.paymentType}</div>
                                                </div>
                                                <div className='subtotal'>
                                                    <div style={{ width: '98%', textAlign: "end" }}>Subtotal :{selectedRow.subTotal}</div>
                                                </div>
                                                <div className='subtotal'>
                                                    <div style={{ width: '98%', textAlign: "end" }}>TAX :{selectedRow.totalTax1}</div>
                                                </div>
                                                <div className='subtotal'>
                                                    <div style={{ width: '98%', textAlign: "end" }}>Tips : {selectedRow.tip}</div>
                                                </div>
                                                <div className='subtotal'>
                                                    <div style={{ width: '98%', textAlign: "end" }}>Discount(0.00%) : {selectedRow.discount}</div>
                                                </div>
                                                <div className='subtotal'>
                                                    <div style={{ width: '98%', textAlign: "end" }}>Total : {selectedRow.orderTotal}</div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className='thank-you'>
                                            ==THANK YOU VISIT AGAIN==
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OnlineOrder;
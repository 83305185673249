import React, { useState } from 'react'
import '../css/admin css/Admin-main.css'
import Department from './inventory/Department';
import ItemDetail from './inventory/ItemDetail';

function Inventory() {
  const [btnValue, setBtnValue] = useState("Department")
  return (
    <>
      <div className='admin-outer'>
        <div className='btn-outer'>
          <button value='Department' onClick={(e) => setBtnValue(e.target.value)}>Department</button>
          <button value='itemDetail' onClick={(e) => setBtnValue(e.target.value)}>Item Detail</button>
          <button value='pizzaPrice' onClick={(e) => setBtnValue(e.target.value)}>Pizza Price</button>
        </div>

        {btnValue === "Department" ? (
          <Department />
        ) : ""}

        {btnValue === "itemDetail" ? (
          <ItemDetail />
        ) : ""}
      </div>
    </>
  )
}

export default Inventory;
import React, { useContext, useEffect, useState } from 'react'
import { userContext } from '../context/Usercontext';
import { Link } from 'react-router-dom';

function AdminNavbar() {
  const { handleBar } = useContext(userContext)


  return (
    <>
      <div className='admin-navbar-outer' >
        <div className='admin-nav-inner'>
          <button onClick={handleBar} className='btn bar'>
            <i className="fa-solid fa-bars"></i>
          </button>
          <h2 className='title'>RANGER POS</h2>
          <div>
            <button className='btn'><i class="fa-regular fa-user"></i></button>
          </div>
        </div>


      </div>
    </>
  )
}

export default AdminNavbar;
import React from 'react'
import { Routes, Route } from "react-router-dom";
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import '../css/main.css'
import Billing from '../components/Billing';
import HeaderState from '../context/Usercontext';
import Items from '../components/Items';
import AdminNavbar from '../admin/AdminNavbar';
import AdminSidebar from '../admin/AdminSidebar';
import Inventory from '../admin/Inventory';
import OnlineOrder from '../admin/online order/OnlineOrder';
import Success from '../components/success/Success';


function Layout() {
    const role = localStorage.getItem('login');
    if (role == 'Adminn') {
        return (
            <>
                <AdminNavbar />
                <div className='main-outer'>
                    <AdminSidebar />
                    <Routes>
                        <Route path="/inventory" element={<Inventory />} />
                        <Route path="/onlineOrder" element={<OnlineOrder />} />
                    </Routes>
                </div>
            </>
        )

    }
    else {
        return (
            <>
                <Navbar />
                <div className='main-outer'>
                    <Sidebar />
                    <Routes>
                        <Route path="/" element={<Items />} />
                        <Route path="/:categoryDescription/:id" element={<Items />} />
                        <Route path="/success" element={<Success />} />
                       
                    </Routes>
                    <Billing />
                </div>
            </>
        )
    }

}

export default Layout;
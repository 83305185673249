import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import axios from 'axios';

function Clover({ totalPrice, tip, gsttotal, pltTotal }) {


    const [name, setName] = useState()
    const [cardNumber, setCardNumber] = useState()
    const [expiryDate, setExpiryDate] = useState()
    const [cvv, setCvv] = useState()

    const handleSubmit = (e) => {
        e.preventDefault();

        const params = {
            nameOnCard: name,
            cardNumber: cardNumber,
            expiryDate: expiryDate,
            cardCVC: cvv,
            amount: (totalPrice + tip + Number(gsttotal) + Number(pltTotal)).toFixed(2)
        }

        axios.post(`${process.env.REACT_APP_URL}/api/Clover`,params).then((res) => {
            console.log(res);
        })
            .catch((res) => {
                console.log(res);
            })
    }
    return (
        <>
            <form method="post" onSubmit={handleSubmit}>
                <div className='clover'>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: "10px 0", width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField id="outlined-basic" label="Name on card*" name="nameOnCard" variant="outlined" onChange={(e) => setName(e.target.value)} />
                        <TextField id="outlined-basic" label="Card number*" name="cardNumber" variant="outlined" onChange={(e) => setCardNumber(e.target.value)} />
                        <TextField id="outlined-basic" label="Expiry date*" name="expiryDate" variant="outlined" onChange={(e) => setExpiryDate(e.target.value)} />
                        <TextField id="outlined-basic" label="CVV*" name="cardCVC" variant="outlined" onChange={(e) => setCvv(e.target.value)} />
                    </Box>
                    <button type='submit' className='pay-btn'>PAY</button>
                </div>
            </form>
        </>
    )
}

export default Clover;
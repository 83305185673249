import React, { useContext, useEffect, useState } from 'react'
import { userContext } from '../context/Usercontext';
import { Link, useNavigate } from 'react-router-dom';

function AdminSidebar() {

  const { bar, setBar, fatchData } = useContext(userContext);

  const navigate = useNavigate()

  useEffect(() => {
    fatchData();
  }, [])
  return (
    <>
      <div className='admin-sidebar-outer desktop-admin-sidebar admin-sidebar-outer'>
        <ul className='admin-catagory'>
          <p className='category-title'>Sales Details</p>

          <li className='link-outer'>
            <Link to='/onlineOrder' className=' link'>
              <span className=' ml-1 text-sm'>Online Order</span>
            </Link>
          </li>
          <p className='category-title'>Inventory</p>

          <li className='link-outer'>
            <Link to='/inventory' className=' link'>
              <span className=' ml-1 text-sm'>Inventory</span>
            </Link>
          </li>

          <p className='category-title'>All Order</p>

          <li className='link-outer'>
            <Link to='/all-order' className=' link'>
              <span className=' ml-1 text-sm'>All Order</span>
            </Link>
          </li>

        </ul>
      </div>

      <div className='mobile'>
        <div className='admin-sidebar-outer' style={bar}>
          <ul className='admin-catagory'>

            <p className='category-title'>Sales Details</p>

            <li className='link-outer'>
              <Link to='/onlineOrder' onClick={() => { setBar({ display: "none" }) }} className=' link'>
                <span className=' ml-1 text-sm'>Online Order</span>
              </Link>
            </li>

            <p className='category-title'>Inventory</p>

            <li className='link-outer'>
              <Link to='/inventory' onClick={() => { setBar({ display: "none" }) }} className=' link'>
                <span className=' ml-1 text-sm'>Inventory</span>
              </Link>
            </li>

            <p className='category-title'>All Order</p>
            <li className='link-outer'>
              <Link to='/all-order' className=' link'>
                <span className=' ml-1 text-sm'>All Order</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default AdminSidebar;
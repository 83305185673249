import React, { useState, useEffect } from 'react';
import loginBg from '../assets/images/login-background.jpg';
import login from '../assets/images/login.png';
import '../../src/css/login.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Login() {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginType, setLoginType] = useState('');

    useEffect(() => {
        const mydata = localStorage.getItem('login_type');
        setLoginType(mydata);
    }, []);
    console.log("-------------------003>",loginType)

    const handleLogin = (e) => {
        e.preventDefault();

        if (loginType === 'admin') {
            axios.get(`https://onlinefoodordering.ca/RangerAPI/testorder/api/User/ValidateUser?userName=${userName}&password=${password}`)
                .then((res) => {
                    console.log("login", res);
                    if (res.status === 200) {
                        navigate('/onlineOrder');
                        localStorage.setItem('loginUser', JSON.stringify(res.data));

                    } else {
                        navigate('/adminlogin');
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        } else if (loginType === 'customer') {
            axios.get(`https://onlinefoodordering.ca/RangerAPI/testorder/api/Customer/ByIdPass?emailId=${email}&password=${password}`)
                .then((res) => {
                    console.log("login", res);
                    if (res.status === 200) {
                        navigate('/onlineOrder');
                        localStorage.setItem('loginUser', JSON.stringify(res.data));
                    } else {
                        navigate('/adminlogin');
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    return (
        <div>
            <form onSubmit={handleLogin}>
                <div className='login-bgimg'>
                    <img src={loginBg} alt="" />
                </div>
                <div className='login-outer'>
                    <div className='login-inner'>
                        <div className='item food-img'>
                            <img src={login} alt="" />
                        </div>
                        <div className='item form-outer'>
                            <div className='form-inner'>
                                <div>
                                    <h3>Login</h3>
                                    <div className='filds'>
                                        {loginType === 'admin' ? (
                                            <>
                                                <h6>User Name</h6>
                                                <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} />
                                            </>
                                        ) : (
                                            <>
                                                <h6>Email Id</h6>
                                                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                            </>
                                        )}
                                    </div>
                                    <div className='filds'>
                                        <h6>Password</h6>
                                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className='btn'>
                                    <button type='submit' className='login'>Login</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Login;

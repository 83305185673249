import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { userContext } from '../../../context/Usercontext';

function Pizza({ spice, setSpice, setPizzaName, pizzacalzone, pdetail, note, setNote, qty, setQty, handeAddtoCart, setProductDetails, setCustomize, setSize, setCrust, size, crust, pizzaName }) {

    const spiceData = ["Hot", "Extra Hot", "Mild", "Med"]
    const { sizedata, setSizeData, crustData, setCrustData } = useContext(userContext)

    // // GET PIZZA SIZE
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/api/PizzaSize/ByProductId?productId=${pdetail.productId}`).then((res) => {
            if (res.status === 200) {
                setSizeData(res.data)
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }, [pdetail.productId])

    // GET PIZZA CRUST
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/api/Modifier/Crusts?sizeId=${size.sizeId}&crustId=0&productId=${pdetail.productId}`).then((res) => {
            if (res.status === 200) {
                setCrustData(res.data)
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }, [pdetail.productId, size.sizeId])

    // GET CUSTOMIZE
    const handleSizeChange = (item) => {
        setSize(item);
    };

    const handleCrustChange = (item) => {
        setCrust(item)
    };

    const handlePizzaChange = (item) => {
        setPizzaName(item)
    };

    const handleSpiceChange = (item) => {
        setSpice(item)
    };

    console.log("pizzacalzone", pizzacalzone);


    useEffect(() => {
        if (sizedata.length > 0 && !size) {
            handleSizeChange(sizedata[0]);
        }
        if (crustData.length > 0 && !crust) {
            handleCrustChange(crustData[0]);
        }
        if (pizzacalzone.length > 0 && !pizzaName) {
            handlePizzaChange(pizzacalzone[0]);
        }
        if (spiceData.length > 0 && !spice) {
            handleSpiceChange(spiceData[0]);
        }
    }, [sizedata, size, crustData, crust, pizzacalzone, pizzaName, spiceData, spice]);


    return (
        <>
            <div className='selection-outer'>
                <div className='pizza-selection'>
                    {pdetail.isPizza === 1 && pdetail.isDeal === 1 && pdetail.isCreateYourOwn === 0 ?
                        <>
                            <h6>Select Pizza Item</h6>
                            <select onChange={(e) => handlePizzaChange(pizzacalzone[e.target.value])}>
                                {pizzacalzone.map((item, index) => (
                                    <option key={index} value={index}>
                                        {item.productName}
                                    </option>
                                ))}
                            </select>
                        </>
                        : ""}
                </div>
                <div className='selection-inner'>
                    <h6>Size</h6>

                    <select onChange={(e) => handleSizeChange(sizedata[e.target.value])}>
                        {sizedata.map((item, index) => (
                            <option key={index} value={index}>
                                {item.sizeName} [${item.sizePriceX1}]
                            </option>
                        ))}
                    </select>

                </div>

                <div className='selection-inner'>
                    {(pdetail.isPizza === 1 && pdetail.isCreateYourOwn === 1) || (pdetail.isDeal === 0) ?
                        <>
                            <h6>Crust</h6>
                            <select onChange={(e) => handleCrustChange(crustData[e.target.value])}>
                                {crustData.map((item, index) => (
                                    <option key={index} value={index}>
                                        {item.modifierName} (${item.modifierCost})
                                    </option>
                                ))}
                            </select>
                        </>
                        : ""}
                    {pdetail.isPizza === 1 && pdetail.isDeal === 1 ?
                        <>
                            <h6>Spice Level</h6>
                            <select onChange={(e) => handleSpiceChange(spiceData[e.target.value])}>
                                {spiceData.map((item, index) => (
                                    <option key={index} value={index}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </>
                        : ""}
                </div>
            </div>


            <div className='note-outer'>
                <span>Notes</span>
                <textarea className='note' value={note} onChange={(e) => setNote(e.target.value)}></textarea>
            </div>
            <div className='qty-inc-dec'>
                <div onClick={() => { if (qty > 1) { setQty(qty - 1) } }}>-</div>
                <input type="text" value={qty} readOnly onChange={(e) => setQty(e.target.value)} />
                <div onClick={() => setQty(qty + 1)}>+</div>
            </div>

            <div className='model-footer'>
                <button onClick={() => setProductDetails(false)} className='cancle'>CANCEL</button>
                <button onClick={() => setCustomize(true)}>CUSTOMIZE</button>
                <button onClick={handeAddtoCart}>ADD TO CART</button>
            </div>
        </>
    )
}

export default Pizza;